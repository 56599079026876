import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Video } from "@ryerson/frontend.media";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Carousel } from "@ryerson/frontend.carousel";
import MultiImageSlide from "../MultiImageSlide/MultiImageSlide";
import { CompanyComponentProps } from "../Company";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { useApplication } from "@ryerson/frontend.application";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";

const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { buttonOrientation = "left", buttonSize = "md" } = props;
		return css`
			width: calc(100% - 0px);
			height: 360px;
			${buttonOrientation === "center"
				? 0
				: getVideoPadding(buttonOrientation, false, buttonSize)};
		`;
	}}
`;

const CompanyCardText = styled.div`
	vertical-align: top;
	width: 80%;
	margin-top: -37px;
`;

const CompanyCardTextMediaRight = styled.div`
	vertical-align: top;
	width: 80%;
	margin: auto;
	margin-left: 0;
	margin-top: -37px;
`;

const CompanyCardTextMultiImage = styled.div`
	vertical-align: top;
	width: 80%;
	margin: auto;
	margin-left: 0;
`;

const Divider = styled.div`
	width: 100%;
	opacity: 0.1;
	margin-top: 20px;
	margin-bottom: 50px;
	border-bottom: 1px solid #002255;
`;

const Section = styled.div`
	margin: 121px 0px;
`;

const MultiImageHeader = styled.div`
	display: inline-block;
	width: calc(100% - 200px);
`;

const MultiImageLogo = styled.div`
	display: inline-block;
	position: relative;
	width: 200px;
`;

const CompanyDesktop: React.FC<CompanyComponentProps> = ({ cardType, content, index }) => {
	const {
		localization: { language },
	} = useApplication();

	const imageStyles = css`
		width: 100%;
	`;

	const logoStyles = css`
		mix-blend-mode: exclusion;
		display: inline-block;
		vertical-align: middle;
		max-width: 170px;
		max-height: 150px;
	`;

	const multiImageLogoStyles = css`
		mix-blend-mode: exclusion;
		display: inline-block;
		vertical-align: middle;
		max-width: 200px;
		position: relative;
		right: 0;
	`;

	const carouselStyles = css`
		width: 100%;
	`;

	switch (cardType) {
		case "mediaLeft":
			return (
				<ContentSection type={(index ?? 0) % 2 === 0 ? "secondary" : "primary"} key={index}>
					<Section>
						<Grid>
							<Row>
								<Column lg={6}>
									{content.hasVideo ? (
										<VideoWrapper buttonOrientation="right">
											<Video
												videoId={content.videoUrl ?? ""}
												buttonOrientation="right"
											></Video>
										</VideoWrapper>
									) : !content.imageUrl ? (
										<></>
									) : (
										<img src={content.imageUrl[0]} css={imageStyles} />
									)}
								</Column>
								<Column lg={1}>
									<></>
								</Column>
								<Column lg={5}>
									<CompanyCardText>
										<Typography
											variant="h1"
											css={css`
												margin-bottom: 30px;
											`}
										>
											{content.title}
										</Typography>
										{content.description.map((text: any, index: number) => {
											return (
												<Typography variant="p" size="md" key={index}>
													{text}
												</Typography>
											);
										})}
									</CompanyCardText>
								</Column>
							</Row>
							<Row>
								<Column lg={7}>
									<></>
								</Column>
								<Column lg={3} align="center">
									<ButtonLink
										to={content.linkUrl}
										linkType={content.linkExternal ? "external" : "internal"}
										label={content.linkText}
									/>
								</Column>
								<Column lg={2} align="center">
									<img src={content.logoImageUrl} css={logoStyles} />
								</Column>
							</Row>
						</Grid>
					</Section>
				</ContentSection>
			);
		case "mediaRight":
			return (
				<ContentSection type={(index ?? 0) % 2 === 0 ? "secondary" : "primary"} key={index}>
					<Section>
						<Grid>
							<Row>
								<Column lg={6}>
									<CompanyCardTextMediaRight>
										<Typography
											variant="h1"
											css={css`
												margin-bottom: 30px;
											`}
										>
											{content.title}
										</Typography>
										{content.description.map((text: any, index: number) => {
											return (
												<Typography key={index} variant="p" size="md">
													{text}
												</Typography>
											);
										})}
									</CompanyCardTextMediaRight>
								</Column>
								<Column lg={6}>
									{content.hasVideo ? (
										<VideoWrapper>
											<Video videoId={content.videoUrl ?? ""}></Video>
										</VideoWrapper>
									) : !content.imageUrl ? (
										<></>
									) : (
										<img src={content.imageUrl[0]} css={imageStyles} />
									)}
								</Column>
							</Row>
							<Row>
								<Column lg={3}>
									<ButtonLink
										to={content.linkUrl}
										linkType={content.linkExternal ? "external" : "internal"}
										label={content.linkText}
									/>
								</Column>
								<Column lg={9}>
									<img src={content.logoImageUrl} css={logoStyles} />
								</Column>
							</Row>
						</Grid>
					</Section>
				</ContentSection>
			);
		case "multiImage":
			return (
				<ContentSection type={(index ?? 0) % 2 === 0 ? "secondary" : "primary"} key={index}>
					<Section>
						<MultiImageHeader>
							<Typography variant="h1">{content.title}</Typography>
						</MultiImageHeader>
						<MultiImageLogo>
							<img src={content.logoImageUrl} css={multiImageLogoStyles} />
						</MultiImageLogo>
						<Divider />
						<Grid>
							<Row>
								<Column lg={6}>
									<CompanyCardTextMultiImage>
										{content.description.map((text: any, index: number) => {
											return (
												<Typography variant="p" size="md" key={index}>
													{text}
												</Typography>
											);
										})}
									</CompanyCardTextMultiImage>
								</Column>
								<Column lg={6}>
									{Array.isArray(content.imageUrl) ? (
										<Carousel
											header={false}
											css={carouselStyles}
											language={language}
										>
											{content.imageUrl.map((image: any, index: number) => (
												<MultiImageSlide
													key={index}
													imageUrl={image}
													imageOverlayText={
														Array.isArray(content.imageStrings)
															? content.imageStrings[index]
															: ""
													}
												/>
											))}
										</Carousel>
									) : (
										<></>
									)}
								</Column>
							</Row>
							<Row>
								<Column lg={6}>
									<></>
								</Column>
								<Column
									lg={6}
									style={css`
										padding-left: 64px;
										padding-top: 20px;
									`}
								>
									<ButtonLink
										to={content.linkUrl}
										linkType={content.linkExternal ? "external" : "internal"}
										label={content.linkText}
									/>
								</Column>
							</Row>
						</Grid>
					</Section>
				</ContentSection>
			);
		default:
			return null;
	}
};

export default CompanyDesktop;
